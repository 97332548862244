<script setup>
import {defineProps} from 'vue';
import SeasonAsset from "@/components/SeasonAsset.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div>
    SERIES {{ props.item.Title }}
    <div v-for="season in parseInt(item.totalSeasons)" :key="season">
      <SeasonAsset :series="item.imdbID" :season="season"/>
    </div>
  </div>
</template>

<style scoped>

</style>