<script>
import WebTorrent from 'webtorrent/dist/webtorrent.min'
import SimplePeer from 'simple-peer'
import axios from "axios";

const rtcConfig = {
  iceServers: [
    {
      urls: [
        "turn:turn.stream.labs.h3.se",
      ],
      username: "test",
      credential: "testme",
    },
    {
      urls:
          ["stun:stun.stream.labs.h3.se"],
      username: "test",
      credential: "testme",
    }],
  iceTransportPolicy: "all",
  iceCandidatePoolSize: 0,
}

const wt = new WebTorrent({
  tracker: {
    rtcConfig: {
      ...SimplePeer.config,
      ...rtcConfig
    }
  }
});

// Old syntax
// const WEBTORRENT_ANNOUNCE = [
//   ['wss://tracker.webtorrent.dev']
// ]
//
// let options = {
//   announce: WEBTORRENT_ANNOUNCE,
//   maxWebConns: 500,
// }

let options = {
  announce: ['wss://tracker.webtorrent.dev', 'wss://tracker.openwebtorrent.com'],
  maxWebConns: 500,
}

const config = {
  default: {
    env: {
      bcqApi: "https://bcq.stream.labs.h3.se",
    }
  }
};

async function searchAssets(imdbId) {
  const url = `${config.default.env.bcqApi}/blocksearch/infoHashAnnotation?imdbId=${imdbId}`;
  const res = await axios.get(url);
  return [res.data];
}

wt.on('warning', function (err) {
  console.error(err)
})
wt.on('error', function (err) {
  console.error(err)
})

navigator.serviceWorker.register('./sw.min.js', {scope: './'}).then(reg => {
  console.log("I am service worker!")

  const worker = reg.active || reg.waiting || reg.installing

  function checkState(worker) {
    console.log("Check state")
    return worker.state === 'activated' && wt.createServer({controller: reg})
  }

  if (!checkState(worker)) {
    worker.addEventListener('statechange', ({target}) => checkState(target))
  }
})

export default {
  data() {
    return {
      id: this.$route.query.id,
      viewingAssets: [],
      peers: 0,
      downloaded: 0,
      progress: 0,
      torrent: undefined,
    };
  },
  methods: {
    next() {
      console.log("next");
    },
    download(hash) {
      const torrent = wt.add(hash, options);

      this.torrent = torrent

      torrent.on('ready', () => {
        // Torrents can contain many files. Let's use the .mp4 file
        const file = torrent.files.find(file => file.name.endsWith('.mp4'))
        const player = document.querySelector('video')
        file.streamTo(player)
        console.log('Ready to play!')
      })

      torrent.on('warning', err => {
        console.log(err)
      });
      torrent.on('error', err => {
        console.log(err)
      });
      torrent.on('wire', wire => {
        console.log(wire)
        console.log("number of peers" + torrent.numPeers)
        this.peers = torrent.numPeers
        this.torrent = torrent
      });
      torrent.on('download', bytes => {
        this.bytes = bytes
        // console.log(bytes)
        // console.log("received: " + torrent.received)
        // console.log("downloaded: " + torrent.downloaded)
        this.downloaded = torrent.downloaded
        this.progress = torrent.progress
        this.torrent = torrent
      });
      torrent.on('upload', bytes => {
        console.log(bytes)
        console.log("uploaded: " + torrent.uploaded)
      });
    }
  },
  async created() {
    const res = await searchAssets(this.id.substring(2, 10))
    this.viewingAssets = res[0];
    console.log(res)
    this.download(this.viewingAssets[0].infoHash)
  }
  ,
  mounted() {
    console.log('mounted: Component has been mounted to the DOM');
  }
  ,
  unmounted() {
    console.log('destroyed: Component has been destroyed');

    if (this.viewingAssets !== undefined) {
      wt.remove(this.viewingAssets[0].infoHash)
      this.viewingAssets = undefined;
    }
  }
}
</script>

<template>
  <div class="hello">

    <video id="video-container" controls="true"></video>
    <div v-if="torrent !== undefined">
      hash: {{torrent.infoHash}}
      peers: {{torrent.numPeers}}
      downloaded: {{downloaded}}
      downloaded: {{torrent.downloaded}}
      progress: {{torrent.progress * 100 }}%
      <button @click="next">Next</button>
    </div>
    <div v-else>
      waiting for torrent
    </div>
  </div>
</template>

<style scoped>
</style>