<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: #0F0F10;
  color: #FFFFFFFF;
  font-family: "Roboto Thin", serif;
}

nav {
  padding: 1em;
}

nav a {
  margin-right: 15px;
  text-decoration: none;
  color: #42b983;
}

nav a.router-link-active {
  font-weight: bold;
}

body {
  padding: 0;
  margin: 0;
  background-color: #0F0F10;
  color: #FFFFFFFF;
  font-family: "Roboto Thin";
}

</style>
