<script>

import axios from "axios";
import EpisodeAsset from "@/components/EpisodeAsset.vue";

const config = {
  default: {
    env: {
      imdbApi: "https://imdb.stream.labs.h3.se",
    }
  }
};

export async function infoSeason(series, season) {
  const url = `${config.default.env.imdbApi}/info/${series}/${season}`;
  const res = await axios.get(url);
  return res.data;
}

export default {
  components: {EpisodeAsset},
  props: {
    series: {
      type: String
    },
    season: {
      type: Number
    }
  },
  data() {
    return {
      info: {
        Episodes: [],
      }
    }
  },
  created() {
    infoSeason(this.series, this.season).then((res) => {
      this.info = res;
    })
  }
}

</script>

<template>
  <div>
    <h1>{{ season }} </h1>
    <div v-for="(episode) in this.info.Episodes" :key="episode.imdbID">
      <EpisodeAsset :info="episode"/>
    </div>
  </div>
</template>

<style scoped>

</style>