<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    view(id) {
      console.log(id);
      this.$router.push({name: 'AssetView', query: {id: id}});
    },
  },
};

</script>

<template>
  THIS IS A MOVIE
  <img :src="item.Poster" alt="the Poster for the movie" @click="view(item.imdbID)"/>
  {{ item }}
</template>

<style scoped>

</style>