<script>

import axios from "axios";

const config = {
  default: {
    env: {
      imdbApi: "https://imdb.stream.labs.h3.se",
    }
  }
};

async function searchTitle(id) {
  const url = `${config.default.env.imdbApi}/info/${id}`;
  const res = await axios.get(url);
  return [res.data];
}


export default {
  data() {
    return {
      asset: {Poster: null},
    }
  },
  props: {
    info: {
      type: Object
    },
  },
  methods: {
    view(id) {
      console.log(id);
      this.$router.push({name: 'AssetView', query: {id: id}});
    },
  },
  created() {
    console.log(this.info);
    searchTitle(this.info.imdbID).then(result => {

      //If we have something
      if (result.length > 0)
        this.asset = result[0];
    })
  }
}
</script>

<template>
  {{ info.Episode }}
  {{ info.Title }}
  {{ info.imdbID }}
  <img :src="asset.Poster" alt="the Poster for the movie" @click="view(asset.imdbID)"/>
</template>

<style scoped>

</style>