import {createRouter, createWebHistory} from 'vue-router';
import SearchPage from '@/views/SearchPage.vue'
import SearchResultPage from "@/views/SearchResultPage.vue";
import AssetViewPage from "@/views/AssetViewPage.vue";

const routes = [
    {
        path: '/',
        name: 'Search',
        component: SearchPage,
    },
    {
        path: '/result',
        name: 'SearchResult',
        component: SearchResultPage,
    },
    {
        path: '/view',
        name: 'AssetView',
        component: AssetViewPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;