<script>
import axios from "axios";
import SeriesAsset from "@/components/SeriesAsset.vue";
import MovieAsset from "@/components/MovieAsset.vue";

const config = {
  default: {
    env: {
      imdbApi: "https://imdb.stream.labs.h3.se",
    }
  }
};

async function searchTitle(query) {
  const url = `${config.default.env.imdbApi}/search?title=${query}`;
  const res = await axios.get(url);
  return [res.data];
}

export default {
  components: {
    SeriesAsset,
    MovieAsset,
  },
  data() {
    return {
      query: this.$route.query.query,
      result: '',
    };
  },
  // Lifecycle Methods
  beforeCreate() {
    console.log('beforeCreate: Component is being initialized');
  },
  created() {
    console.log('created: Component has been created');
  },
  beforeMount() {
    console.log('beforeMount: Component is about to be mounted to the DOM');
    console.log(this.query);
    console.log(this.$route.query);
    searchTitle(this.query).then(result => {
      this.result = result;
    });
  },
  mounted() {
    console.log('mounted: Component has been mounted to the DOM');
  },
  beforeUpdate() {
    console.log('beforeUpdate: Component data is about to be updated');
  },
  updated() {
    console.log('updated: Component data has been updated');
  },
  beforeUnmount() {
    console.log('beforeDestroy: Component is about to be destroyed');
  },
  unmounted() {
    console.log('destroyed: Component has been destroyed');
  },
  methods: {
    async search() {
      console.log(this.query);

      if (this.query) {
        const res = await searchTitle(this.query);
        console.log(res);
        // this.$router.push({ name: 'SearchResults', query: { q: this.query } });
      }
    },
  },
};


</script>

<template>
  <div id="searchResult">
    <div v-for="item in result" :key="item.id">
      <div v-if="item.Type === 'series'">
        <SeriesAsset :item="item"></SeriesAsset>
      </div>
      <div v-else-if="item.Type === 'movie'">
        <MovieAsset :item="item"></MovieAsset>
      </div>
      {{ item }}
    </div>

  </div>
</template>

<style scoped>

</style>